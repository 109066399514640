// extracted by mini-css-extract-plugin
export var _break = "Page-module--_break--aFK1z";
export var active = "Page-module--active--wi546";
export var anchor = "Page-module--anchor--JDo1G";
export var article = "Page-module--article--WqU5J";
export var author = "Page-module--author--ph3N+";
export var blockFullPage = "Page-module--blockFullPage--iIoyD";
export var blockOne = "Page-module--blockOne--aZBvx";
export var blockTwo = "Page-module--blockTwo--pm5aA";
export var blocks = "Page-module--blocks--dcoDw";
export var box = "Page-module--box--dibuk";
export var button = "Page-module--button--+-dqC";
export var container = "Page-module--container--Hq-aX";
export var date = "Page-module--date--V2j+f";
export var disabled = "Page-module--disabled--XqSDb";
export var employee = "Page-module--employee--7oJnO";
export var extraMargin = "Page-module--extraMargin--TrYF2";
export var featuredImage = "Page-module--featuredImage--9wyHa";
export var green = "Page-module--green--IGySP";
export var heading = "Page-module--heading--O3ttD";
export var icon = "Page-module--icon--+0u1p";
export var list = "Page-module--list--BiFfY";
export var listItem = "Page-module--listItem--CQ+46";
export var news = "Page-module--news--xrjzH";
export var next = "Page-module--next--e9daS";
export var paginate = "Page-module--paginate--JoYiJ";
export var previous = "Page-module--previous--EYRHU";
export var subHeader = "Page-module--subHeader--B0JcF";
export var tag = "Page-module--tag--zFiKq";
export var tagsWrapper = "Page-module--tagsWrapper--hU+3j";
export var white = "Page-module--white--9ZgUd";