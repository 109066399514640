import React from "react";

import clsx from "clsx";

import ArrowsBadgeIcon from "@js/component/icon/badge/ArrowsBadgeIcon";
import Box from "@js/component/Box";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import { BoxProps } from "@js/component/Box";

import * as classNames from "@css/component/BoxCompare.module.scss";

/**
 * @type BoxCompareProps
 */
export type BoxCompareProps = Omit<BoxProps, "children">;

/**
 * @const BoxCompare
 */
const BoxCompare = (props: BoxProps) => {
    const {
        className,
        ...restProps
    } = props;
    return (
        <Box { ...restProps } className={ clsx(classNames.boxCompare, className) } element="section">
            <header className={ classNames.header }>
                <ArrowsBadgeIcon className={ classNames.icon } />
                <span className={ classNames.title }>Vergelijk energielabel-adviseurs</span>
            </header>
            <p className={ classNames.paragraph }>
                Vergelijk op prijs, kwaliteit en snelheid op basis van jouw gegevens.
            </p>
            <LinkButton className={ classNames.linkButton } to="/vergelijk">
                <span>Vergelijk nu</span>
                <LongArrowRightIcon className={ classNames.icon } />
            </LinkButton>
        </Box>
    );
};

export default BoxCompare;
